* {
    box-sizing: border-box;
}

.centering { 
    width: 444px;
    height: 444px;
    position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
    margin: auto;
    /* display: flex; */
}

.canvas {
    width: 100%;
    height: 444px;
    position: relative;
    font-size: 16px;
    /* flex: 1 1 100%; */
}

.bird-body {
    width: 11.25rem;
    height: 11.25rem;
    position: absolute;
    background-color: black;
    border-radius: 50%;
    top: 7rem;
    overflow: hidden;
}

.whitey-beak {
    width: 0;
    height: 0;
    border-top: 2rem solid black;
    border-left: 2rem solid transparent;
    transform: rotate(1deg);
    position: absolute;
    top: 8.5rem;
    left: 3rem
}


.yellow-beak {
    width: 0;
    height: 0;
    border-top: 2rem solid black;
    border-right: 2rem solid transparent;
    transform: rotate(-10deg);
    position: absolute;
    top: 8.5rem;
    right: 3.2rem
}


.whitey-tail.one {
    position: absolute;
    height: 6rem;
    width: 2rem;
    background-color: #5c6667;
    border-radius: 0 0 100%;
    top: 18rem;
    left: 7rem;
    transform: rotate(5deg);
}

.whitey-tail.two {
    position: absolute;
    height: 6rem;
    width: 2rem;
    background-color: #5c6667;
    border-radius: 0 0 0 100% ;
    top: 18rem;
    left: 7.75rem;
    transform: rotate(-5deg);
}

.yellow-tail {
    position: absolute;
    height: 8rem;
    width: 0.75rem;
    background-color: #5c6667;
    top: 16rem;
    right: 14rem;
    transform: rotate(35deg);
}

.bird-body.left {
    left: 3rem;
}

.face-whitey {
    background-color: #edeee9;
    position: absolute;
    width: 9.2rem;
    height: 6rem;
    transform: skew(20deg);
    top: 2rem;
    right: -1rem;
}

.face-yellow {
    width: 0;
    height: 0;
    border-bottom: 7rem solid #edeee9;
    border-left: 7rem solid transparent;
    transform: rotate(37deg);
    position: absolute;
    top: -1rem;
    left: 1.5rem;
}

.eye.whitey {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    top: 1.5rem;
    left: 3.5rem;
    border: 0.15rem solid #edeee9;
}

.eye.yellow {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    top: 1.75rem;
    right: 3.5rem;
    border: 0.15rem solid #edeee9;
}

.belly-whitey {
    background-color: #edeee9;
    width: 11.25rem;
    height: 8rem;
    position: relative;
    top: 4rem;
}

.chest-yellow {
    background: linear-gradient(to right, #eac984, #edeee9);
    float: left;
    margin-left: -0.5rem;
    width: 3rem;
    height: 8rem;
    transform: rotate(5deg);
}

.wings {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 9rem solid #857c6b;
    border-right: 10.7rem solid transparent;
    top: 10.7rem;
    right: 3.7rem;
    transform: rotate(10deg);
}

.wing-bulge {
    height: 9rem;
    width: 1rem;
    border-radius: 100% 100% 100% 100%;
    background-color: #857c6b;
    transform: rotate(10deg);
    position: absolute;
    top: 9.8rem;
    right: 13.7rem;
}

.feathers-whitey {
    background-color: #a7a7a5;
    width: 5rem;
    height: 9rem;
    position: absolute;
    left: 8rem;
    top: -2rem;
}

.feathers-whitey-stripes {
    background-color: #848484;
    width: 0.20rem;
    height: 9rem;
    position: absolute;
}

.feathers-whitey-stripes.one {    
    left: 0.20rem;
}

.feathers-whitey-stripes.two {
    left: 0.60rem;
}

.feathers-whitey-stripes.three {
    left: 1rem;
}

.feathers-whitey-stripes.four {
    left: 1.4rem;
}

.feathers-whitey-stripes.five {
    left: 1.8rem;
}

.feathers-whitey-stripes.six {
    left: 2.2rem;
}

.feathers-whitey-stripes.seven {
    left: 2.6rem;
}

.bird-body.right {
    right: 3rem;
}

.belly-yellow {
    background-color: #eac984;
    width: 15rem;
    height: 8rem;
    position: relative;
    top: 4rem;
    right: 1rem;
    transform: rotate(10deg);
}

.branch {
    background-color: #a6a6a4;
    position: absolute;
    top: 16rem;
    width: 100%;
    height: 0.4rem;
}

.toe.one {
    width: 0.20rem;
    height: 1rem;
    position: absolute;
    top: 15.7rem;
    left: 6rem;
    background-color: #5c6667;
}

.toe.two {
    width: 0.20rem;
    height: 1rem;
    position: absolute;
    top: 15.7rem;
    left: 6.7rem;
    background-color: #5c6667;
}

.toe.three {
    width: 0.20rem;
    height: 1.5rem;
    position: absolute;
    top: 15.25rem;
    left: 7.4rem;
    background-color: #5c6667;
}

.toe.four {
    width: 0.20rem;
    height: 1rem;
    position: absolute;
    top: 15.7rem;
    left: 9rem;
    background-color: #5c6667;
}

.toe.five {
    width: 0.20rem;
    height: 1rem;
    position: absolute;
    top: 15.7rem;
    left: 9.7rem;
    background-color: #5c6667;
}

.toe.six {
    width: 0.20rem;
    height: 1.5rem;
    position: absolute;
    top: 15.25rem;
    left: 10.4rem;
    background-color: #5c6667;
}

.toe-connectarch.one {
    width: 0.9rem;
    height: 1rem;
    border: 0.20rem solid #5c6667;
    border-top-left-radius: 110px; 
    border-top-right-radius: 110px;
    border-bottom: 0;
    position: absolute;
    top: 15.4rem;
    left:6rem;
}

.toe-connectarch.two {
    width: 0.9rem;
    height: 1rem;
    border: 0.20rem solid #5c6667;
    border-top-left-radius: 110px; 
    border-top-right-radius: 110px;
    border-bottom: 0;
    position: absolute;
    top: 15.4rem;
    left:6.7rem;
}

.toe-connectarch.three {
    width: 0.9rem;
    height: 1rem;
    border: 0.20rem solid #5c6667;
    border-top-left-radius: 110px; 
    border-top-right-radius: 110px;
    border-bottom: 0;
    position: absolute;
    top: 15.4rem;
    left:9rem;
}

.toe-connectarch.four {
    width: 0.9rem;
    height: 1rem;
    border: 0.20rem solid #5c6667;
    border-top-left-radius: 110px; 
    border-top-right-radius: 110px;
    border-bottom: 0;
    position: absolute;
    top: 15.4rem;
    left:9.7rem;
}

.wing-right {
    background-color: #a6a6a6;
    position: absolute;
    width: 12rem;
    height: 3.5rem;
    border-radius: 50% / 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transform: rotate(-40deg);
    top: -7.5rem;
    left: -2rem;
    overflow: hidden;
}

.stripey.one {
    background-color: #617472;
    position: absolute;
    height: 13rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -3.3rem;
    left: 5.8rem
}

.stripey.two {
    background-color: #617472;
    position: absolute;
    height: 13rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -3.9rem;
    left: 5.8rem
}

.stripey.three {
    background-color: #617472;
    position: absolute;
    height: 13.25rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -4.6rem;
    left: 5.8rem
}

.stripey.four {
    background-color: #617472;
    position: absolute;
    height: 13.25rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -5.2rem;
    left: 5.8rem
}

.stripey.five {
    background-color: #617472;
    position: absolute;
    height: 13.25rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -5.8rem;
    left: 5.8rem
}

.stripey.six {
    background-color: #617472;
    position: absolute;
    height: 13.25rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -6.3rem;
    left: 5.8rem
}

.wing-left {
    background-color: #a6a6a6;
    position: absolute;
    width: 8rem;
    height: 3.5rem;
    border-radius: 60% 60% 0 70%;
    transform: rotate(82deg) skew(30deg);
    top: -6.5rem;
    left: -3.5rem;
    overflow: hidden;
}


.stripey.seven {
    background-color: #617472;
    position: absolute;
    height: 13.25rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -6.3rem;
    left: 5.8rem
}

.stripey.eight {
    background-color: #617472;
    position: absolute;
    height: 13.25rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -5.8rem;
    left: 5.8rem
}

.stripey.nine {
    background-color: #617472;
    position: absolute;
    height: 13.25rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -5.25rem;
    left: 5.8rem
}

.stripey.ten {
    background-color: #617472;
    position: absolute;
    height: 13.25rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -4.7rem;
    left: 5.8rem
}

.stripey.eleven {
    background-color: #617472;
    position: absolute;
    height: 13.25rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -4.1rem;
    left: 5.8rem
}

.stripey.tweleve {
    background-color: #617472;
    position: absolute;
    height: 13.25rem;
    width: 0.3rem;
    transform: rotate(90deg);
    top: -3.6rem;
    left: 5.8rem
}

.yellow-feet {
    position: absolute;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 100%;
    border-top: 0.30rem solid #5c6667;
    border-right: 0.30rem solid #5c6667;
    border-left: 0.30rem solid #5c6667;
    border-bottom: 0.30rem solid whitesmoke;
    transform: rotate(-30deg);
    right: 3.3rem;
    bottom: 10.5rem;
}


.contact {
    font-size: 1rem;
    padding-top: 2em;
}