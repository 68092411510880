.directory-pages {
    pointer-events: all;
    text-decoration: none;
    text-align: left;
    color: rgb(10, 40, 175);
    opacity: 0.6;
    text-shadow: 1px 1px 1px #b1098c;
  }
  
  .directory-pages:hover {
    color: rgb(64, 9, 126);
    padding-left: 50px;
    opacity: 1;
    text-shadow: 2px 2px 2px #b1098c;
    text-decoration: underline;
  }
  
  .contact {
      color: "#ff6090";
      box-shadow: "0 0 0 5px #ff6090";
      padding: "0 20px";
  }
